import React from "react";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";

import { SuccessModal } from "cvr-frontend";
import { CmsRichText } from "cms";
import { ICmsData } from "cms/types/cmsTypes";
import { SetupGuide } from "generic";

import {
  confirmationPageKeys,
  confirmationPageTitles,
} from "../helpers/setupGuideKeys";

const Confirmation = () => {
  const {
    cmsData: { loading: cmsLoading },
  } = useSelector((state: { cms: ICmsData }) => state.cms);

  return (
    <div className="confirmation-page-container">
      <SuccessModal />

      {cmsLoading ? (
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div className="d-flex flex-row justify-content-center align-items-center">
            <Spinner animation="border" variant="primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </div>
      ) : (
        <SetupGuide
          CmsRichText={CmsRichText}
          contentCssName="setup-guide"
          setupGuideKeys={confirmationPageKeys}
          setupGuideTitles={confirmationPageTitles}
          contentPath="confirmation"
        />
      )}
    </div>
  );
};

export default Confirmation;
