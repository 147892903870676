import { configureStore } from "@reduxjs/toolkit";

import {
  cvrMappingSlice,
  preferenceSlice,
  searchWindowSlice,
} from "cvr-frontend";

import { cmsSlice } from "cms";

const store = configureStore({
  reducer: {
    cvrMapping: cvrMappingSlice,
    preference: preferenceSlice,
    searchWindow: searchWindowSlice,
    cms: cmsSlice,
  },
});

export default store;
