import React from "react";
import "./App.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import MappingWindow from "./pages/MappingWindow";
import BulkUpdate from "./pages/BulkUpdate";
import SearchWindow from "./pages/SearchWindow";
import Subscription from "./pages/Subscription";
import Redirect from "./pages/Redirect";
import NavBarPage from "./pages/NavBarPage";
import QuerySearchWindow from "./pages/QuerySearchWindow";
import SetupGuidePage from "./pages/SetupGuidePage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/mappings" element={<MappingWindow />} />
        <Route path="/bulkupdate" element={<BulkUpdate />} />
        <Route path="/searchwindow" element={<SearchWindow />} />
        <Route path="/global-search" element={<SearchWindow />} />
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/redirect" element={<Redirect />} />
        <Route path="/setup" element={<NavBarPage />} />
        <Route path="/query-search" element={<QuerySearchWindow />} />
        <Route path="/setupguide" element={<SetupGuidePage />} />
      </Routes>
    </Router>
  );
}

export default App;
