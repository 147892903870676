import { createSlice } from "@reduxjs/toolkit";

import { fetchStatus } from "./mappingSlice";

const initialState = {
  customerNumberPreference: {
    value: "action",
  },
  isContactSyncRulesSaved: false,
  triggerAppContactFilterPreference: undefined,
  actionAppContactFilterPreference: undefined,
  triggerAppCompanyFilterPreference: undefined,
  actionAppCompanyFilterPreference: undefined,
  triggerAppContactNumberPreference: undefined,
  triggerAppCompanyNumberPreference: undefined,
  isContactCheckbox: false,
  isVatIncluded: false,
};

export const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    onChangeCustomerNumberPreference(state, { payload }) {
      state.customerNumberPreference = payload.selectedOption;
      if (state.customerNumberPreference.value === "action") {
        state.triggerAppCompanyNumberPreference = {
          name: "Do not add it anywhere",
          value: "doNotAdd",
        };
        state.triggerAppContactNumberPreference = {
          name: "Do not add it anywhere",
          value: "doNotAdd",
        };
      } else {
        state.triggerAppContactNumberPreference = undefined;
        state.triggerAppCompanyNumberPreference = undefined;
      }
      state.isContactSyncRulesSaved = false;
    },
    onChangeCheckboxForContact(state) {
      state.isContactCheckbox = !state.isContactCheckbox;
      state.isContactSyncRulesSaved = false;
    },
    onChangeVatIncluded(state) {
      state.isVatIncluded = !state.isVatIncluded;
      state.isContactSyncRulesSaved = false;
    },
    onChangeContactPreferences(state, { payload }) {
      if (payload.selectingFor === "triggerAppContactFilterField") {
        state.triggerAppContactFilterPreference = {
          name: payload.selectedOption.HRF,
          value: payload.selectedOption.CRF,
        };
        state.isContactSyncRulesSaved = false;
      } else if (payload.selectingFor === "actionAppContactFilterField") {
        state.actionAppContactFilterPreference = {
          name: payload.selectedOption.HRF,
          value: payload.selectedOption.CRF,
        };
        state.isContactSyncRulesSaved = false;
      } else if (payload.selectingFor === "triggerAppCompanyFilterField") {
        state.triggerAppCompanyFilterPreference = {
          name: payload.selectedOption.HRF,
          value: payload.selectedOption.CRF,
        };
        state.isContactSyncRulesSaved = false;
      } else if (payload.selectingFor === "actionAppCompanyFilterField") {
        state.actionAppCompanyFilterPreference = {
          name: payload.selectedOption.HRF,
          value: payload.selectedOption.CRF,
        };
        state.isContactSyncRulesSaved = false;
      } else if (payload.selectingFor === "triggerAppContactNumberField") {
        state.triggerAppContactNumberPreference = {
          name: payload.selectedOption.HRF,
          value: payload.selectedOption.CRF,
        };
        state.isContactSyncRulesSaved = false;
      } else if (payload.selectingFor === "triggerAppCompanyNumberField") {
        state.triggerAppCompanyNumberPreference = {
          name: payload.selectedOption.HRF,
          value: payload.selectedOption.CRF,
        };
        state.isContactSyncRulesSaved = false;
      }
    },
    setIsContactSyncRulesSaved(state) {
      state.isContactSyncRulesSaved = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStatus.fulfilled, (state, actions) => {
      const { preferences } = actions.payload;
      const { customerSync } = preferences;
      const { customerNumber, searchFilterOptions, syncOptions, includeVat } =
        customerSync;

      if (customerNumber.direction) {
        state.customerNumberPreference.value = customerNumber.direction;
      }

      if (customerNumber.mapping && customerNumber.mapping.triggerApp) {
        for (const key in customerNumber.mapping.triggerApp) {
          if (
            Object.prototype.hasOwnProperty.call(
              customerNumber.mapping.triggerApp,
              key
            )
          ) {
            const property =
              customerNumber?.mapping?.triggerApp[key]?.triggerProperty;
            if (property) {
              if (key === "companies" || key === "organization") {
                state.triggerAppCompanyNumberPreference = property;
              } else if (key === "contacts" || key === "person") {
                state.triggerAppContactNumberPreference = property;
              }
            }
          }
        }
      }

      if (searchFilterOptions) {
        if (searchFilterOptions.triggerApp) {
          for (const key in searchFilterOptions.triggerApp) {
            if (
              Object.prototype.hasOwnProperty.call(
                searchFilterOptions.triggerApp,
                key
              )
            ) {
              const triggerAppFilterProperty =
                searchFilterOptions?.triggerApp[key]?.filterProperty;
              const actionAppFilterProperty =
                searchFilterOptions?.triggerApp[key]?.actionApp?.filterProperty;
              if (triggerAppFilterProperty && actionAppFilterProperty) {
                if (key === "companies" || key === "organization") {
                  state.triggerAppCompanyFilterPreference =
                    triggerAppFilterProperty;
                  state.actionAppCompanyFilterPreference =
                    actionAppFilterProperty;
                } else if (key === "contacts" || key === "person") {
                  state.triggerAppContactFilterPreference =
                    triggerAppFilterProperty;
                  state.actionAppContactFilterPreference =
                    actionAppFilterProperty;
                }
              }
            }
          }
        }
      }
      if (syncOptions) {
        state.isContactCheckbox = syncOptions?.triggerApp?.useSecondarySync;
      }

      if (includeVat) {
        state.isVatIncluded = includeVat;
      }

      if (
        state.triggerAppCompanyFilterPreference &&
        state.actionAppCompanyFilterPreference &&
        state.triggerAppContactNumberPreference
      ) {
        state.isContactSyncRulesSaved = true;
      }
    });
  },
});

export const {
  onChangeCustomerNumberPreference,
  onChangeContactPreferences,
  onChangeCheckboxForContact,
  setIsContactSyncRulesSaved,
  onChangeVatIncluded,
} = contactSlice.actions;

export default contactSlice.reducer;
