import axios from "axios";
import { getUrlInCvr } from "./url";
import {
  setShowSuccessModal,
  setShowErrorModal,
} from "../slices/preferenceSlice";
import { setIsMappingsSaved } from "../slices/cvrMappingSlice";
export const saveMappings = async (props) => {
  const {
    dispatch,
    setIsLoading,
    mappings,
    userIds,
    contactCreation,
    mappingKeys,
  } = props;

  try {
    setIsLoading(true);
    const fields = {};

    mappingKeys.map((mappingKey) => {
      fields[mappingKey] = {};
    });

    Object.keys(mappings).forEach((item) => {
      for (const key in mappings[item]) {
        if (mappings[item][key].value) {
          fields[item][key] = {
            label: mappings[item][key].label,
            value: mappings[item][key].value,
            description: mappings[item][key].description,
          };
        }
        console.log(fields);
      }
    });
    await axios.post(getUrlInCvr("REACT_APP_SAVE_MAPPINGS"), {
      fields,
      ...userIds,
      createContacts: contactCreation,
    });
    dispatch(setIsMappingsSaved());
    dispatch(setShowSuccessModal({ message: "Mappings Saved Successfully" }));
  } catch (error) {
    console.log(error.message);
    let message;
    if (error.response && error.response.data.message)
      message = error.response.data.message;
    else message = error.message;
    dispatch(setShowErrorModal({ message }));
  } finally {
    setIsLoading(false);
  }
};
